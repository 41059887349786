import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
} from "redux-persist/es/constants";
import blogReducer from "@/redux/blog/blog.reducer.js";
import productReducer from "@/redux/product/product.reducer.js";
import memberReducer from "@/redux/member/member.reducer.js";
import bantuanPesertaReducer from "@/redux/bantuan-peserta/bantuan-peserta.reducer.js";
import transactionReducer from "@/redux/transaction/transaction.reducer.js";
import participantReducer from "@/redux/participant/participant.reducer.js";
import { changelogApi } from "@/services/changelog.service.js";
import { quizApi } from "@/services/quiz.service.js";
import { legalDocumentApi } from "@/services/legal-document.service.js";
import { broadcastCandidateApi } from "@/services/broadcast-candidate.service.js";
import { authApi } from "@/services/auth.service.js";
import { financeAccountApi } from "@/services/finance-account.service.js";

const store = configureStore({
	reducer: persistReducer(
		{
			key: "root",
			storage,
			whitelist: "auth",
		},
		combineReducers({
			blog: blogReducer,
			product: productReducer,
			member: memberReducer,
			assist: bantuanPesertaReducer,
			transaction: transactionReducer,
			participant: participantReducer,

			// RTK Queries
			[authApi.reducerPath]: authApi.reducer,
			[changelogApi.reducerPath]: changelogApi.reducer,
			[quizApi.reducerPath]: quizApi.reducer,
			[legalDocumentApi.reducerPath]: legalDocumentApi.reducer,
			[broadcastCandidateApi.reducerPath]: broadcastCandidateApi.reducer,
			[financeAccountApi.reducerPath]: financeAccountApi.reducer,
		}),
	),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}).concat(
			thunk,
			authApi.middleware,
			changelogApi.middleware,
			quizApi.middleware,
			legalDocumentApi.middleware,
			broadcastCandidateApi.middleware,
			financeAccountApi.middleware,
		),
	devTools: true,
});
const persistor = persistStore(store);

export { store, persistor };
