import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: axiosFetchBase("", ""),
	tagTypes: ["auth"],
	endpoints: (build) => ({
		login: build.mutation({
			query: (data) => ({
				url: "/login",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["auth"],
		}),
	}),
});

export const { useLoginMutation } = authApi;
