import { axiosFetchBase } from "./http";
import { createApi } from "@reduxjs/toolkit/query/react";

export const broadcastCandidateApi = createApi({
	reducerPath: "broadcastCandidateApi",
	tagTypes: ["broadcast-candidates"],
	baseQuery: axiosFetchBase("/broadcast/candidate"),
	endpoints: (builder) => ({
		importCandidate: builder.mutation({
			query: (data) => ({
				url: "/import",
				method: "POST",
				body: data,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
		}),
		createCandidate: builder.mutation({
			query: (data) => ({
				url: "/create",
				method: "POST",
				body: data,
			}),
		}),
		updateCandidate: builder.mutation({
			query: (data) => ({
				url: "/update",
				method: "POST",
				body: data,
			}),
		}),
		deleteCandidate: builder.mutation({
			query: (data) => ({
				url: "/delete",
				method: "POST",
				body: data,
			}),
		}),
	}),
});
export const {
	useImportCandidateMutation,
	useCreateCandidateMutation,
	useUpdateCandidateMutation,
	useDeleteCandidateMutation,
} = broadcastCandidateApi;
