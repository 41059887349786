import {
	CREATE_PRODUCT,
	CREATE_PRODUCT_DOCUMENTATION,
	CREATE_PRODUCT_DOCUMENTATION_LOADING,
	CREATE_PRODUCT_LOADING,
	DELETE_PRODUCT,
	DELETE_PRODUCT_GUIDE,
	DELETE_PRODUCT_GUIDE_LOADING,
	DELETE_PRODUCT_LOADING,
	GET_PRODUCT_RECAP_DATA,
	GET_PRODUCT_RECAP_LOADING,
	UPDATE_PRODUCT,
	UPDATE_PRODUCT_DESCRIPTION,
	UPDATE_PRODUCT_DESCRIPTION_LOADING,
	UPDATE_PRODUCT_LOADING,
	UPDATE_PRODUCT_SCORE,
	UPDATE_PRODUCT_SCORE_LOADING,
	UPDATE_STATUS_PRODUCT,
	UPDATE_STATUS_PRODUCT_LOADING,
	PRODUCT_HAS_ENDED,
	PRODUCT_HAS_ENDED_LOADING
} from "./product.types";

const INITIAL_STATE = {
	recap_list: {
		payload: null,
		error: null,
		loading: false,
	},
	create_product: {
		payload: null,
		error: null,
		loading: false,
	},
	update_product: {
		payload: null,
		error: null,
		loading: false,
	},
	delete_product: {
		payload: null,
		error: null,
		loading: false,
	},
	update_product_description: {
		payload: null,
		error: null,
		loading: false,
	},
	delete_product_guide: {
		payload: null,
		error: null,
		loading: false,
	},
	update_product_score: {
		payload: null,
		error: null,
		loading: false,
	},
	update_product_status: {
		payload: null,
		error: null,
		loading: false,
	},
	create_product_documentation: {
		payload: null,
		error: null,
		loading: false,
	},
};

const productReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PRODUCT_RECAP_LOADING:
			return { ...state, recap_list: { loading: action.loading || true } };
		case GET_PRODUCT_RECAP_DATA:
			return {
				...state,
				recap_list: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case CREATE_PRODUCT_LOADING:
			return {
				...state,
				create_product: {
					loading: action.isLoading,
				},
			};
		case CREATE_PRODUCT:
			return {
				...state,
				create_product: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case UPDATE_PRODUCT_LOADING:
			return {
				...state,
				update_product: {
					loading: action.isLoading,
				},
			};
		case UPDATE_PRODUCT:
			return {
				...state,
				update_product: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case DELETE_PRODUCT_LOADING:
			return {
				...state,
				delete_product: {
					loading: action.isLoading,
				},
			};
		case DELETE_PRODUCT:
			return {
				...state,
				delete_product: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case UPDATE_PRODUCT_DESCRIPTION_LOADING:
			return {
				...state,
				update_product_description: {
					loading: action.isLoading,
				},
			};
		case UPDATE_PRODUCT_DESCRIPTION:
			return {
				...state,
				update_product_description: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case UPDATE_STATUS_PRODUCT_LOADING:
			return {
				...state,
				update_product_status: {
					loading: action.isLoading,
				},
			};
		case UPDATE_STATUS_PRODUCT:
			return {
				...state,
				update_product_status: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case PRODUCT_HAS_ENDED_LOADING:
			return {
				...state,
				product_has_ended: {
					loading: action.isLoading,
				},
			};
		case PRODUCT_HAS_ENDED:
			return {
				...state,
				product_has_ended: {
					payload: action.payload,
					error: action.error,
					loading: false,
				}

			}
		case DELETE_PRODUCT_GUIDE_LOADING:
			return {
				...state,
				delete_product_guide: {
					loading: action.isLoading,
				},
			};
		case DELETE_PRODUCT_GUIDE:
			return {
				...state,
				delete_product_guide: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case UPDATE_PRODUCT_SCORE_LOADING:
			return {
				...state,
				update_product_score: {
					loading: action.isLoading,
				},
			};
		case UPDATE_PRODUCT_SCORE:
			return {
				...state,
				update_product_score: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case CREATE_PRODUCT_DOCUMENTATION_LOADING:
			return {
				...state,
				create_product_documentation: {
					loading: action.isLoading,
				},
			};
		case CREATE_PRODUCT_DOCUMENTATION:
			return {
				...state,
				create_product_documentation: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default productReducer;
