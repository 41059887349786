import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const quizApi = createApi({
	reducerPath: "quizApi",
	tagTypes: ["quizzes"],
	baseQuery: axiosFetchBase("/quiz"),
	endpoints: (builder) => ({
		scoreQuiz: builder.query({
			query: (score) => ({
				url: `/score/${score.type}/${score.id}`,
				method: "GET",
			}),
		}),
		createQuiz: builder.mutation({
			query: (data) => ({
				url: "/create",
				method: "POST",
				body: data,
			}),
			invalidatesTags: [{ type: "quizzes", id: "list" }],

		}),
		updateQuiz: builder.mutation({
			query: (data) => ({
				url: `/update`,
				method: "PUT",
				body: data,
			}),
		}),
		deleteQuiz: builder.mutation({
			query: (data) => ({
				url: `/delete/${data.selectedQuiz}`,
				method: "DELETE",
				body: data,
			}),
			invalidatesTags: (_, __, arg) => [
				{ type: "quizzes", id: "list" },
				{ type: "quizzes", id: arg.id },
			],
		}),
	}),
});

export const {
	useScoreQuizQuery,
	useCreateQuizMutation,
	useUpdateQuizMutation,
	useDeleteQuizMutation,
} = quizApi;
