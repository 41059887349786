import { TYPES_MEMBER } from "./member.types";

const INITIAL_STATE = {
	list: {
		payload: null,
		error: null,
		loading: false,
	},
	update: {
		payload: null,
		error: null,
		loading: false,
	},
	update_password: {
		payload: null,
		error: null,
		loading: false,
	},
};

const memberReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TYPES_MEMBER.GET_MEMBER_LIST_LOADING:
			return { ...state, list: { loading: action.loading || true } };
		case TYPES_MEMBER.GET_MEMBER_LIST_DATA:
			return {
				...state,
				list: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case TYPES_MEMBER.SET_ACTIVATE_MEMBER_LOADING:
			return {
				...state,
				acivate: {
					loading: action.isLoading,
				},
			};
		case TYPES_MEMBER.POST_ACTIVATE_MEMBER:
			return {
				...state,
				activate: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case TYPES_MEMBER.UPDATE_PROFILE_MEMBER_LOADING:
			return {
				...state,
				update: {
					loading: action.isLoading,
				},
			};
		case TYPES_MEMBER.UPDATE_PROFILE_MEMBER:
			return {
				...state,
				update: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case TYPES_MEMBER.UPDATE_PASSWORD_MEMBER_LOADING:
			return {
				...state,
				update_password: {
					loading: action.isLoading,
				},
			};
		case TYPES_MEMBER.UPDATE_PASSWORD_MEMBER:
			return {
				...state,
				update_password: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default memberReducer;
