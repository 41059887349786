export const GET_PRODUCT_RECAP_DATA = "GET_PRODUCT_RECAP_DATA";
export const GET_PRODUCT_RECAP_LOADING = "GET_PRODUCT_RECAP_LOADING";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_LOADING = "CREATE_PRODUCT_LOADING";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_LOADING = "UPDATE_PRODUCT_LOADING";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_LOADING = "DELETE_PRODUCT_LOADING";

export const UPDATE_STATUS_PRODUCT = "UPDATE_STATUS_PRODUCT";
export const UPDATE_STATUS_PRODUCT_LOADING = "UPDATE_STATUS_PRODUCT_LOADING";

export const PRODUCT_HAS_ENDED_LOADING = "PRODUCT_HAS_ENDED_LOADING";
export const PRODUCT_HAS_ENDED = "PRODUCT_HAS_ENDED";

export const UPDATE_PRODUCT_DESCRIPTION = "UPDATE_PRODUCT_DESCRIPTION";
export const UPDATE_PRODUCT_DESCRIPTION_LOADING =
	"UPDATE_PRODUCT_DESCRIPTION_LOADING";

export const DELETE_PRODUCT_GUIDE = "DELETE_PRODUCT_GUIDE";
export const DELETE_PRODUCT_GUIDE_LOADING = "DELETE_PRODUCT_GUIDE_LOADING";

export const UPDATE_PRODUCT_SCORE = "UPDATE_PRODUCT_SCORE";
export const UPDATE_PRODUCT_SCORE_LOADING = "UPDATE_PRODUCT_SCORE_LOADING";

export const CREATE_PRODUCT_DOCUMENTATION = "CREATE_PRODUCT_DOCUMENTATION";
export const CREATE_PRODUCT_DOCUMENTATION_LOADING =
	"CREATE_PRODUCT_DOCUMENTATION_LOADING";

export const DELETE_PRODUCT_DOCUMENTATION = "DELETE_PRODUCT_DOCUMENTATION";
export const DELETE_PRODUCT_DOCUMENTATION_LOADING =
	"DELETE_PRODUCT_DOCUMENTATION_LOADING";
