import { UPLOAD_PHOTO, UPLOAD_PHOTO_LOADING } from "./participant.types";

const INITIAL_STATE = {
	upload_photo: {
		payload: null,
		error: null,
		loading: false,
	},
};

const participantReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UPLOAD_PHOTO_LOADING:
			return {
				...state,
				upload_photo: {
					loading: action.isLoading,
				},
			};
		case UPLOAD_PHOTO:
			return {
				...state,
				upload_photo: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default participantReducer;
