import { CONSTANTS } from "@/utils";
import axios, { AxiosError } from "axios";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const http = axios.create({
	baseURL: API_BASE_URL,
	timeout: 10_000, // bail after 10 seconds
});

const getAuthConfig = () => {
	const token = localStorage.getItem(CONSTANTS.AUTH_TOKEN);
	return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
};

/**
 * Fetch data using axios
 * @param {Object} args
 * @param {string} args.url
 * @param {string} args.baseUrl
 * @param {string} args.method
 * @param {Object} args.headers
 * @param {Object|FormData} args.data
 * @param {AbortSignal} args.signal
 * @param {(e: ProgressEvent) => void} args.onUploadProgress
 * @param {(e: ProgressEvent) => void} args.onDownloadProgress
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function fetchWithAxios(args) {
	return http(args.url, {
		baseURL: args.baseUrl,
		signal: args.signal,
		data: args.data,
		method: args.method === "GET" ? "GET" : "POST",
		headers: {
			...getAuthConfig().headers,
			...args.headers,
		},
		onUploadProgress: (progressEvent) =>
			args.onUploadProgress?.({
				progressEvent,
				progress: Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				),
			}),
		onDownloadProgress: (progressEvent) =>
			args.onDownloadProgress?.({
				progressEvent,
				progress: Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				),
			}),
	});
}

/**
 * Factory function to create a fetch function for rtk query using axios
 * @param {string} baseUrl In the form of `/name`. Ex: `/user`, `/changelog`, etc.
 * @param {string} [axiosBaseUrl] The base url for axios. If not provided, it will use the API_BASE_URL
 * @returns {(function(*, *): Promise<any|{error: string}|{error: string}|undefined>)|*}
 */
export function axiosFetchBase(baseUrl, axiosBaseUrl) {
	return async (args, api) => {
		if (args.initial) return { data: args.initial };
		try {
			const formData = new FormData();
			for (const key in args.body) {
				formData.append(key, args.body[key]);
			}
			// laravel / php workaround since they only support GET and POST method
			if (args.method !== "GET") {
				formData.append("_method", args.method);
			}

			const queryParams = new URLSearchParams(args.query).toString();

			const baseFetchArgs = {
				baseUrl: axiosBaseUrl ?? API_BASE_URL,
				url: `${baseUrl}${args.url}?${queryParams}`,
				signal: api.signal,
				data: formData,
				method: args.method,
				headers: args.headers,
				onUploadProgress: args.onUploadProgress,
				onDownloadProgress: args.onDownloadProgress,
			};

			// upload files sequentially so we get the progress of each file
			// only specific to multiple files upload
			if (args.files?.length > 0) {
				const responses = [];
				for (let i = 0; i < args.files.length; i++) {
					const fileFormData = new FormData();
					fileFormData.append("file", args.files[i]);
					const response = await fetchWithAxios({
						...baseFetchArgs,
						data: fileFormData,
						onUploadProgress: (progressEvent) =>
							args.onUploadProgress?.({
								...progressEvent,
								index: i,
							}),
						onDownloadProgress: (progressEvent) =>
							args.onDownloadProgress?.({
								...progressEvent,
								index: i,
							}),
					});
					responses.push(response);
				}
				return {
					data: responses.map((response) => response.statusText),
				};
			}

			const response = await fetchWithAxios({
				...baseFetchArgs,
			});
			if (response.status >= 200 && response.status < 300) {
				return { data: response.data.payload };
			}
			return { error: response.statusText };
		} catch (e) {
			let message = "Internal Server Error";
			if (e instanceof AxiosError) {
				message = e.response.data.message;
			} else if (e instanceof Error) {
				message = e.message;
			}
			return { error: message };
		}
	};
}

export default http;

export { getAuthConfig, API_BASE_URL };
