import {
	DELETE_TRANSACTION,
	DELETE_TRANSACTION_LOADING,
	MOVE_TRANSACTION_PRODUCT,
	MOVE_TRANSACTION_PRODUCT_LOADING,
	REFUND_TRANSACTION_STATUS,
	REFUND_TRANSACTION_STATUS_LOADING,
} from "./transaction.types";

const INITIAL_STATE = {
	delete_transaction: {
		payload: null,
		error: null,
		loading: false,
	},
	refund_transaction_status: {
		payload: null,
		error: null,
		loading: false,
	},
	move_transaction_product: {
		payload: null,
		error: null,
		loading: false,
	},
};

const transactionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DELETE_TRANSACTION_LOADING:
			return {
				...state,
				delete_transaction: {
					loading: action.isLoading,
				},
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				delete_transaction: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case REFUND_TRANSACTION_STATUS_LOADING:
			return {
				...state,
				refund_transaction_status: {
					loading: action.isLoading,
				},
			};
		case REFUND_TRANSACTION_STATUS:
			return {
				...state,
				refund_transaction_status: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case MOVE_TRANSACTION_PRODUCT_LOADING:
			return {
				...state,
				move_transaction_product: {
					loading: action.isLoading,
				},
			};
		case MOVE_TRANSACTION_PRODUCT:
			return {
				...state,
				move_transaction_product: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default transactionReducer;
