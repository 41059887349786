import { TYPES_BANTUAN_PESERTA } from "./bantuan-peserta.types";

const INITIAL_STATE = {
	login: {
		payload: null,
		error: null,
		loading: false,
	},
};

const bantuanPesertaReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TYPES_BANTUAN_PESERTA.ASSIST_LOGIN_LOADING:
			return { ...state, login: { loading: action.loading || true } };
		case TYPES_BANTUAN_PESERTA.ASSIST_LOGIN:
			return {
				...state,
				login: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default bantuanPesertaReducer;
