const AUTH_TOKEN = "AUTH_TOKEN";

const PRODUCT_TYPE = [
	{ value: "Produk Baru", label: "Produk Baru" },
	{ value: "Produk Lama", label: "Produk Lama" },
];

const INHOUSE_STATUS = [
	{ value: 1, label: "Penawaran" },
	{ value: 2, label: "Deal" },
	{ value: 3, label: "Belum direspon" },
	{ value: 4, label: "Cancel" },
	{ value: "", label: "Semua" },
]
const MARKET_PRODUCT_TYPE = [
	{ value: "Pasar Baru", label: "Pasar Baru" },
	{ value: "Pasar Lama", label: "Pasar Lama" },
];

const STRATEGI_GENERIK_ANSOFF = [
	{ value: "Diversifikasi", label: "Diversifikasi" },
	{ value: "Pengembangan Pasar", label: "Pengembangan Pasar" },
	{ value: "Pengembangan Produk", label: "Pengembangan Produk" },
	{ value: "Intensifikasi", label: "Intensifikasi" },
];

const GENDER = [
	{ value: "Bapak", label: "Bapak" },
	{ value: "Ibu", label: "Ibu" },
];

const QUESTION_TYPE = [
	{ value: "pilihan-ganda", label: "Pilihan Ganda" },
	{ value: "isian-singkat", label: "Isian Singkat" },
	{ value: "menjodohkan", label: "Menjodohkan" },
	{ value: "benar-salah", label: "Benar-salah" },
];

const TIME_CHOICE = [
	{ value: 15, label: "15 detik" },
	{ value: 30, label: "30 detik" },
	{ value: 45, label: "45 detik" },
	{ value: 60, label: "1 menit 0 detik" },
	{ value: 75, label: "1 menit 15 detik" },
	{ value: 90, label: "1 menit 30 detik" },
	{ value: 105, label: "1 menit 45 detik" },
	{ value: 120, label: "2 menit 0 detik" },
	{ value: 135, label: "2 menit 15 detik" },
	{ value: 150, label: "2 menit 30 detik" },
];

const BENAR_SALAH = [
	{ value: 1, label: "Benar" },
	{ value: 0, label: "Salah" },
];

const QUESTION_POINTS = [
	{ value: 1, label: "1 poin" },
	{ value: 2, label: "2 poin" },
	{ value: 3, label: "3 poin" },
	{ value: 4, label: "4 poin" },
	{ value: 5, label: "5 poin" },
	{ value: 6, label: "6 poin" },
	{ value: 7, label: "7 poin" },
	{ value: 8, label: "8 poin" },
	{ value: 9, label: "9 poin" },
	{ value: 10, label: "10 poin" },
	{ value: 11, label: "11 poin" },
	{ value: 12, label: "12 poin" },
	{ value: 13, label: "13 poin" },
	{ value: 14, label: "14 poin" },
	{ value: 15, label: "15 poin" },
	{ value: 16, label: "16 poin" },
	{ value: 17, label: "17 poin" },
	{ value: 18, label: "18 poin" },
	{ value: 19, label: "19 poin" },
	{ value: 20, label: "20 poin" },
];

const RKAP = [
	{ value: "1", label: "Tambahkan ke RKAP" },
	{ value: "2", label: "Tambahkan ke RKAP REVISI" },
	{ value: "3", label: "Produk NON RKAP" },
];

const COLOR_BLUE_IPBT = "#2bb2e7";
const COLOR_GREEN_IPBT = "#a6ce39";
const COLOR_RED_IPBT = "#ee1d23";
const COLOR_ORANGE_IPBT = "#f58220";
const COLOR_YELLOW_IPBT = "#feca0a";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const SHOWPAGINATION = [
	{ value: 10, label: "10" },
	{ value: 20, label: "20" },
	{ value: 50, label: "50" },
	{ value: 100, label: "100" },
];

const CATEGORYKPI = [
	{ value: "routine", label: "Routine" },
	{ value: "improvement", label: "Improvement" },
	{ value: "tambahan", label: "Tambahan" },
];

const PERSPEKTIF = [
	{ value: "finansial", label: "Finansial" },
	{ value: "pelanggan", label: "Pelanggan" },
	{ value: "proses bisnis", label: "Proses bisnis" },
	{
		value: "pembelajaran dan pertumbuhan",
		label: "Pembelajaran dan pertumbuhan",
	},
    { value: 'it', label: 'Information Technology' }
];

const DIRECTUR = "Muhammad Sigit Susanto";

// TRANSACTION_STATUS
const TRANSACTION_PENDING = 2;
const TRANSACTION_PIUTANG = 3;
const TRANSACTION_SUCCESS = 4;
const TRANSACTION_PDDM = 5;
const TRANSACTION_REFUND = 6;

// INAHEX EXHIBITOR
const KATEGORI_PLATINUM = 1;
const KATEGORI_GOLD = 2;
const KATEGORI_SILVER = 3;
const KATEGORI_BRONZE = 4;

// TYPE IPP
const TARGET_IPP_NUMBER = 1;
const TARGET_IPP_DATE = 2;
const TARGET_IPP_PERCENTAGE = 3;
const TARGET_IPP_AVERAGE = 4;

// STATUS BROADCAST
const BROADCAST_DRAFT = 0;
const BROADCAST_SENDING = 1;
const BROADCAST_SENDED = 2;
const BROADCAST_SCHEDULE = 3;

const PROFESI = [
	{
		label: "Dosen",
		value: "Dosen",
	},
	{
		label: "Peneliti",
		value: "Peneliti",
	},
	{
		label: "Dokter Hewan",
		value: "Dokter Hewan",
	},
	{
		label: "Paramedik veteriner",
		value: "Paramedik veteriner",
	},
	{
		label: "Mahasiswa pascasarjana",
		value: "Mahasiswa pascasarjana",
	},
	{
		label: "QA",
		value: "QA",
	},
	{
		label: "QC",
		value: "QC",
	},
	{
		label: "Operasional",
		value: "Operasional",
	},
	{
		label: "Marketing",
		value: "Marketing",
	},
	{
		label: "Keuangan",
		value: "Keuangan",
	},
	{
		label: "Akuntansi",
		value: "Akuntansi",
	},
	{
		label: "HR (SDM)",
		value: "HR (SDM)",
	},
	{
		label: "Legal",
		value: "Legal",
	},
	{
		label: "Administrasi",
		value: "Administrasi",
	},
	{
		label: "General Affairs",
		value: "General Affairs",
	},
	{
		label: "Salesperson",
		value: "Salesperson",
	},
	{
		label: "Laboran",
		value: "Laboran",
	},
	{
		label: "Data analist",
		value: "Data analist",
	},
	{
		label: "Sistem informasi",
		value: "Sistem informasi",
	},
	{
		label: "Teknologi informasi",
		value: "Teknologi informasi",
	},
	{
		label: "Lainnya",
		value: "Lainnya",
	},
];

export const CONSTANTS = {
	AUTH_TOKEN,
	PRODUCT_TYPE,
	INHOUSE_STATUS,
	MARKET_PRODUCT_TYPE,
	STRATEGI_GENERIK_ANSOFF,
	GENDER,
	QUESTION_TYPE,
	BENAR_SALAH,
	TIME_CHOICE,
	QUESTION_POINTS,
	RKAP,
	COLOR_BLUE_IPBT,
	COLOR_GREEN_IPBT,
	COLOR_RED_IPBT,
	COLOR_ORANGE_IPBT,
	COLOR_YELLOW_IPBT,
	SUPPORTED_FORMATS,
	SHOWPAGINATION,
	CATEGORYKPI,
	PERSPEKTIF,
	DIRECTUR,
	TRANSACTION_PENDING,
	TRANSACTION_PIUTANG,
	TRANSACTION_SUCCESS,
	TRANSACTION_PDDM,
	TRANSACTION_REFUND,
	KATEGORI_PLATINUM,
	KATEGORI_GOLD,
	KATEGORI_SILVER,
	KATEGORI_BRONZE,
	TARGET_IPP_NUMBER,
	TARGET_IPP_DATE,
	TARGET_IPP_PERCENTAGE,
	TARGET_IPP_AVERAGE,
	BROADCAST_DRAFT,
	BROADCAST_SENDED,
	BROADCAST_SENDING,
	BROADCAST_SCHEDULE,
	PROFESI,
};
