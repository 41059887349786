export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_TRANSACTION_LOADING = "DELETE_TRANSACTION_LOADING";

export const REFUND_TRANSACTION_STATUS = "REFUND_TRANSACTION_STATUS";
export const REFUND_TRANSACTION_STATUS_LOADING =
	"REFUND_TRANSACTION_STATUS_LOADING";

export const MOVE_TRANSACTION_PRODUCT = "MOVE_TRANSACTION_PRODUCT";
export const MOVE_TRANSACTION_PRODUCT_LOADING =
	"MOVE_TRANSACTION_PRODUCT_LOADING";
