import { DELETE_ARTICLE, DELETE_ARTICLE_LOADING } from "./blog.types";

const INITIAL_STATE = {
	delete_article: {
		payload: null,
		error: null,
		loading: false,
	},
};

const blogReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DELETE_ARTICLE_LOADING:
			return {
				...state,
				delete_article: {
					loading: action.isLoading,
				},
			};
		case DELETE_ARTICLE:
			return {
				...state,
				delete_article: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default blogReducer;
