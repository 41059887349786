export const GET_MEMBER_LIST_DATA = "GET_MEMBER_LIST_DATA";
export const GET_MEMBER_LIST_LOADING = "GET_MEMBER_LIST_LOADING";

export const POST_ACTIVATE_MEMBER = "POST_ACTIVATE_MEMBER";
export const SET_ACTIVATE_MEMBER_LOADING = "SET_ACTIVATE_MEMBER_LOADING";

export const UPDATE_PROFILE_MEMBER = "UPDATE_PROFILE_MEMBER";
export const UPDATE_PROFILE_MEMBER_LOADING = "UPDATE_PROFILE_MEMBER_LOADING";

export const UPDATE_PASSWORD_MEMBER = "UPDATE_PASSWORD_MEMBER";
export const UPDATE_PASSWORD_MEMBER_LOADING = "UPDATE_PASSWORD_MEMBER_LOADING";

export const TYPES_MEMBER = {
	GET_MEMBER_LIST_DATA,
	GET_MEMBER_LIST_LOADING,
	POST_ACTIVATE_MEMBER,
	SET_ACTIVATE_MEMBER_LOADING,
	UPDATE_PROFILE_MEMBER,
	UPDATE_PROFILE_MEMBER_LOADING,
	UPDATE_PASSWORD_MEMBER,
	UPDATE_PASSWORD_MEMBER_LOADING,
};
